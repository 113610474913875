import * as React from "react"
import styled from "@emotion/styled"

import {
  horizontal,
  vertical,
  borderRadii,
  colours,
  typographyStyles,
} from "../index"

export type CalloutType = "yellow" | "white"

export type GradientCalloutType = "gradient"

interface InternalGradientProps {
  kind: GradientCalloutType
  gradientStart: string
  gradientEnd: string
}

interface InternalSolidColoursProps {
  kind: CalloutType
}

type InternalProps = InternalSolidColoursProps | InternalGradientProps

const InternalCallout = styled.div<InternalProps>`
  ${typographyStyles.bodyBold};
  height: 40px;
  display: inline-block;
  padding: ${vertical.xxs} ${horizontal.s};
  text-align: center;
  border-radius: ${borderRadii.xs};
  box-sizing: border-box;
  white-space: nowrap;
  color: ${colours.offBlack};
  background: ${props => toBackgroundColor(props)};
`

const toBackgroundColor = (props: InternalProps): string => {
  switch (props.kind) {
    case "yellow":
      return colours.callout.main
    case "white":
      return colours.white
    case "gradient":
      return `
        ${props.gradientStart}
        linear-gradient(
          135deg,
          ${props.gradientStart},
          ${props.gradientEnd}
        )
      `
  }
}

type Props = InternalProps & {
  text: string
  className?: string
}

export const Callout: React.FC<Props> = props => (
  <InternalCallout {...props}>{props.text}</InternalCallout>
)
