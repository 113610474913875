import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  BodySmall,
  breakpoints,
  Card as DKCard,
  colours,
  column,
  DoneIcon,
  GridRow,
  HeadlineLarge,
  horizontal,
  ScalesLegalIcon,
  Statement2,
  Callout,
  TypewriterIcon,
  typographyStyles,
  vertical,
  iconSize,
} from "design-kit"

const Background = styled.section`
  background-color: ${colours.greyScale.grey25};
  padding-top: ${vertical.xl};
  padding-bottom: ${vertical.xl};

  ${breakpoints.desktop`
    padding-top: ${vertical.xxl};
    padding-bottom: ${vertical.xxl};
  `}
`

const TextContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 5, // the cards need more space on a narrow desktop screen, otherwise they squish
  })}
`

const CardsContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 7, // the cards need more space on a narrow desktop screen, otherwise they squish
  })}

  ${breakpoints.tablet`
    display: flex;
  `}
`

const CardStyled = styled(DKCard)`
  position: relative;
  overflow: hidden;
  padding: ${vertical.l} ${horizontal.l};
  margin-top: ${vertical.m};

  ${breakpoints.tablet`
    padding: ${vertical.l} ${horizontal.l};

    :last-of-type {
      margin-left: ${horizontal.s};
    }
  `}

  ${breakpoints.desktop`
    padding: ${vertical.l} ${horizontal.l};
    margin-top: 0;
  `}
`

const SnazzyBar = styled.div<{ gradientStart: string; gradientEnd: string }>`
  background-color: ${props => props.gradientStart};
  background-image: ${props =>
    `linear-gradient(135deg, ${props.gradientStart} 0%, ${props.gradientEnd} 100%)`};
  width: 100%;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 2px solid ${colours.offBlack};
`

const CardTitle = styled.h3`
  ${typographyStyles.headlineBold}
  margin-top: ${vertical.xs};
`

interface CardProps {
  title: React.ReactElement
  tagText: string
  gradientStart: string
  gradientEnd: string
  icon: React.ReactElement
  services: string[]
}
const Card: React.FC<CardProps> = ({
  title,
  tagText,
  gradientStart,
  gradientEnd,
  icon,
  services,
}) => {
  return (
    <CardStyled element="article">
      <SnazzyBar gradientStart={gradientStart} gradientEnd={gradientEnd} />

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        {icon}
        <Callout
          kind="gradient"
          gradientStart={gradientStart}
          gradientEnd={gradientEnd}
          text={tagText}
        />
      </div>

      {title}

      <ul
        css={css`
          margin-top: ${vertical.s};
        `}
      >
        {services.map(item => {
          return (
            <li
              key={item}
              css={css`
                display: flex;

                :not(:first-of-type) {
                  margin-top: ${vertical.xs};
                }
              `}
            >
              <DoneIcon color={colours.action.main} />
              <BodySmall
                css={css`
                  margin-left: ${horizontal.s};
                `}
              >
                {item}
              </BodySmall>
            </li>
          )
        })}
      </ul>
    </CardStyled>
  )
}

export const WhatWeOffer: React.FC = () => {
  return (
    <Background>
      <GridRow>
        <TextContainer>
          <Statement2>What we offer</Statement2>
          <div
            css={css`
              margin-top: ${vertical.s};
            `}
          >
            <HeadlineLarge>An end-to-end homebuying experience.</HeadlineLarge>
            <HeadlineLarge>Just need a mortgage? It’s free.</HeadlineLarge>
            <HeadlineLarge>Need the legal stuff too? No problem!</HeadlineLarge>
          </div>
        </TextContainer>

        <CardsContainer>
          <Card
            title={
              <CardTitle>
                Expert mortgage advice{" "}
                <span
                  css={css`
                    display: block;
                  `}
                >
                  &amp; application service
                </span>
              </CardTitle>
            }
            tagText="Free service"
            gradientStart={colours.gradientBase.sunshine}
            gradientEnd={colours.gradientBase.bubblegum}
            icon={<TypewriterIcon width={iconSize.l} height={iconSize.l} />}
            services={[
              "Digital application service",
              "Expert mortgage advice",
              "Personalised recommendation",
              "Tracking until mortgage offer",
            ]}
          />
          <Card
            title={
              <CardTitle>
                <span
                  css={css`
                    ${typographyStyles.headline}
                  `}
                >
                  Habito Plus:{" "}
                </span>
                Property survey,{" "}
                <span
                  css={css`
                    display: block;
                  `}
                >
                  legal work &amp; more
                </span>
              </CardTitle>
            }
            tagText="Starting from £2,000"
            gradientStart={colours.gradientBase.seafoam}
            gradientEnd={colours.gradientBase.mistyViolet}
            icon={<ScalesLegalIcon height={iconSize.l} width={iconSize.l} />}
            services={[
              "Conveyancing solicitor",
              "Level 2 or 3 Survey",
              "Personal Customer Success Expert",
              "Tracking until completion",
            ]}
          />
        </CardsContainer>
      </GridRow>
    </Background>
  )
}
