import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  Body,
  BodyBold,
  breakpoints,
  column,
  colours,
  GridRow,
  Headline,
  HeadlineBold,
  horizontal,
  resets,
  useMediaQuery,
  vertical,
} from "design-kit"

import { copyProvider } from "./copy"
import timeLineStart from "../assets/linestart.png"
import timeLineStartTablet from "../assets/linestart-tablet.png"
import timeLineMid from "../assets/line.png"
import { Situation } from "../types"

const Wave = styled.img`
  width: 100%;
  height: 100%;
  user-select: none;
`

export const Timeline: React.FC<{ situation: Situation }> = ({ situation }) => {
  const isNonMobile = useMediaQuery(breakpoints.tablet.query)
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const copy = copyProvider(situation)

  return (
    <React.Fragment>
      {isNonMobile ? (
        <ol
          css={css`
            ${resets.list};
            position: relative;
            margin-top: ${vertical.xl};
            ${breakpoints.tablet`
                margin-top: ${vertical.xxl};
              `}
          `}
        >
          {copy.map((item, i) => {
            const isOdd = i % 2 === 1
            return (
              <li key={item.title}>
                <GridRow
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      ${column({
                        widthTablet: 2,
                        widthDesktop: 4,
                      })}
                      text-align: right;
                      margin-top: -12px;
                    `}
                  >
                    {!isOdd && (
                      <React.Fragment>
                        <HeadlineBold>{item.title}</HeadlineBold>
                        <Headline>{item.text}</Headline>
                      </React.Fragment>
                    )}
                  </div>

                  <div
                    css={css`
                      ${column({
                        widthTablet: 2,
                        widthDesktop: 4,
                      })}
                      position: relative;
                    `}
                  >
                    <div
                      css={css`
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: ${colours.action.main};
                        ${!isOdd
                          ? `
                            top: -10px;
                            left: -10px;
                          `
                          : `
                            top: -10px;
                            right: -10px;
                          `}
                      `}
                    />
                    {i === 0 && isNonMobile && !isDesktop ? (
                      <Wave src={timeLineStartTablet} alt="" />
                    ) : i === 0 ? (
                      <Wave src={timeLineStart} alt="" />
                    ) : i + 1 === copy.length ? null : isOdd ? (
                      <Wave src={timeLineMid} alt="" />
                    ) : (
                      <Wave
                        src={timeLineMid}
                        alt=""
                        css={css`
                          transform: scaleX(-1);
                        `}
                      />
                    )}
                  </div>

                  <div
                    css={css`
                      ${column({
                        widthTablet: 2,
                        widthDesktop: 4,
                      })}
                      margin-top: -12px;
                    `}
                  >
                    {isOdd && (
                      <React.Fragment>
                        <HeadlineBold>{item.title}</HeadlineBold>
                        <Headline>{item.text}</Headline>
                      </React.Fragment>
                    )}
                  </div>
                </GridRow>
              </li>
            )
          })}
        </ol>
      ) : (
        <ol
          css={css`
            ${column({
              widthMobile: 4,
            })}
            margin-top: ${vertical.l};
          `}
        >
          {copy.map(item => {
            return (
              <li
                key={item.title}
                css={css`
                  position: relative;
                  display: flex;
                  padding-bottom: ${vertical.m};
                  padding-left: ${horizontal.m};
                  margin-left: 8px;

                  :not(:last-of-type) {
                    border-left: 2px solid ${colours.action.main};
                  }

                  :last-of-type {
                    border-left: 2px solid transparent;
                  }

                  :before {
                    content: "";
                    background-color: ${colours.action.main};
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -9px;
                    width: 16px;
                    height: 16px;
                  }
                `}
              >
                <div
                  css={css`
                    margin-top: -4px;
                  `}
                >
                  <BodyBold>{item.title}</BodyBold>
                  <Body>{item.text}</Body>
                </div>
              </li>
            )
          })}
        </ol>
      )}
    </React.Fragment>
  )
}
