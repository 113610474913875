import { Situation } from "../types"

type Copy = {
  title: string
  text: string
}[]

// if we add more items and end up with an even number of them,
// please make sure the Margharita Pool still looks good
export const copyProvider = (situation: Situation): Copy => [
  {
    title: "We find you the best mortgage",
    text: "We search through thousands of deals to find the best one for you",
  },
  {
    title: "We send off your mortgage application",
    text: "Book the survey and meet your conveyancer",
  },
  {
    title: "We kick off the legal work",
    text: "Your conveyancer will sort out legal checks and draw up the contracts",
  },
  {
    title: "We sort your property survey",
    text: "Your surveyor will write a detailed report on your future home",
  },
  {
    title:
      situation === "NTB"
        ? "You’ve sold one place and bought another!"
        : "You’ve got the property!",
    text: "Pay the final part of your fee and pick up those keys!",
  },
]
