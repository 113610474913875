import React, { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  column,
  GridRow,
  Headline,
  PrimaryLink,
  RadioButtonGroup,
  Statement2,
  typographyStyles,
  useHasMounted,
  useMediaQuery,
  vertical,
} from "design-kit"

import martiniPoolPng from "../assets/martini-pool.png"
import martiniPoolWebp from "../assets/martini-pool.webp"

import { Timeline } from "./Timeline"
import { Situation } from "../types"
import { logButtonClick, logLinkClick } from "../amplitude"

const Background = styled.section`
  background-color: #98d9ff;
  background-image: linear-gradient(#98d9ff 0%, #ffc1f9 100%);
  padding-top: ${vertical.xl};

  ${breakpoints.desktop`
    padding-top: ${vertical.xxl};
  `}
`

const BottomTitle = styled.p`
  ${typographyStyles.headline1}
`

export const HowItWorks: FC = () => {
  const hasMounted = useHasMounted()
  const isNonMobile = useMediaQuery(breakpoints.tablet.query)

  const [situation, setSituation] = React.useState<Situation>("FTB")

  return (
    <Background>
      <GridRow>
        <div
          css={css`
            text-align: center;
            ${column({
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 8,
              offsetDesktop: 2,
            })}
          `}
        >
          <Statement2>How it works</Statement2>
          <Headline
            css={css`
              margin-bottom: ${vertical.s};

              ${breakpoints.tablet`margin-bottom: ${vertical.m};`}
            `}
          >
            What’s your situation?
          </Headline>

          <RadioButtonGroup
            title=""
            buttons={[
              { label: "I’m buying", value: "FTB" },
              { label: "I’m selling and buying", value: "NTB" },
            ]}
            name="situation-ftb-ntb"
            layout={{
              mobile: "column",
              tablet: "row",
              desktop: "row",
            }}
            onChange={val => {
              setSituation(val)
              logButtonClick(val, "how_it_works_timeline")
            }}
            selectedValue={situation}
          />
        </div>

        {hasMounted && <Timeline situation={situation} />}

        <div
          css={css`
            ${column({
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 12,
            })}
            text-align: center;
            padding-bottom: ${vertical.xl};

            ${breakpoints.desktop`
              position: relative;
              padding-bottom: ${vertical.xxl};
            `}
          `}
        >
          {isNonMobile && (
            <picture
              css={css`
                display: none;

                ${breakpoints.tablet`
                  display: block;
                  position: absolute;
                  bottom: -20px;
                  right: -44px;

                  img {
                    width: 320px;
                  }
                `}

                ${breakpoints.desktop`
                  bottom: -25px;
                  right: 0;

                  img {
                    width: 386px;
                  }
                `}
              `}
            >
              <source src={martiniPoolWebp} type="image/webp" />

              <img src={martiniPoolPng} alt="" loading="lazy" />
            </picture>
          )}

          <BottomTitle
            css={css`
              margin-top: ${vertical.l};
            `}
          >
            Sound good?
          </BottomTitle>
          <PrimaryLink
            text="Let’s do this"
            href="/how-can-we-help/new-mortgage"
            descriptiveText="Let’s do this: sign up"
            onClick={() => {
              logLinkClick(
                "lets_do_this",
                "/how-can-we-help/new-mortgage",
                "how_it_works_timeline"
              )
            }}
            width={{
              mobile: "full-width",
              tablet: "content-width",
              desktop: "content-width",
            }}
            css={css`
              margin-top: ${vertical.m};
            `}
          />
        </div>
      </GridRow>
    </Background>
  )
}
