import React, { FC } from "react"
import styled from "@emotion/styled"

import { colours } from "design-kit"

import { HeroUpper } from "./HeroUpper"
import { DashboardDisplay } from "./DashboardDisplay"
import { GradientsBackground } from "./GradientsBackground"

const Background = styled.section`
  position: relative;
  background-color: ${colours.gradientBase.bubblegum};
  overflow: hidden;
`

export const Hero: FC = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null)

  return (
    <Background>
      <GradientsBackground />
      <HeroUpper scrollRef={scrollRef} />
      <div ref={scrollRef}></div>
      <DashboardDisplay />
    </Background>
  )
}
