/**
 * Copied from $MONOREPO/marketing/views/Homepage/Reviews.
 * Only changed the copy.
 */
import { FC } from "react"
import styled from "@emotion/styled"
import { useTrustpilot } from "../../../shared-components/Trustpilot/hooks"

import {
  GridRow,
  breakpoints,
  colours,
  column,
  TrustpilotLogo,
  typographyStyles,
  vertical,
} from "design-kit"

import { ReviewCards } from "./ReviewCards"

const Background = styled.section`
  background-color: ${colours.offBlack};
  padding-top: ${vertical.l};
  padding-bottom: ${vertical.l};
  position: relative;
`

const TitleContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 4,
  })}
`

const TrustPilotLogoContainer = styled.div`
  margin-bottom: ${vertical.m};

  ${breakpoints.tablet`
    margin-bottom: ${vertical.l};
  `}

  > img {
    max-width: 70%;
  }
`

const Title = styled.h2`
  ${typographyStyles.headline1};
  color: ${colours.white};
  margin-bottom: ${vertical.xs};
`

const ReviewsContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 8,
  })}
`

export const Reviews: FC = () => {
  const { stars } = useTrustpilot()
  return (
    <Background>
      <GridRow>
        <TitleContainer>
          <TrustPilotLogoContainer>
            <TrustpilotLogo tint={colours.white} stars={stars} />
          </TrustPilotLogoContainer>

          <Title>Why they think you’ll love it</Title>
        </TitleContainer>

        <ReviewsContainer>
          <ReviewCards />
        </ReviewsContainer>
      </GridRow>
    </Background>
  )
}
