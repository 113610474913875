export interface Review {
  quote: string
  reviewer: string
  date: string
}

export const reviews: Review[] = [
  {
    quote:
      "Fantastic service. We have used Habito twice and the process is really simple and Joe Lavin has been fantastic throughout. Thoroughly recommend.",
    reviewer: "Paul",
    date: "27th February 2024",
  },

  {
    quote:
      "Knowledgeable and patient. Kris responded quickly to my queries and offered full transparency into all the mortgages on offer.",
    reviewer: "Myla",
    date: "27th February 2024",
  },

  {
    quote:
      "All pretty good, steps explained to me on the chat. Was shown lots of different options and they always got back to me very quickly. Been a big stress reducer just having one person to chase.",
    reviewer: "Callum",
    date: "27th February 2024",
  },

  {
    quote:
      "Great! Habito has been fantastic from the start. As first-time buyers we feel very fortunate to have found Raj and Dan to help us through this process",
    reviewer: "Rodrigo",
    date: "22nd February 2024",
  },

  {
    quote:
      "We used Habito for our previous remortgage and it was a no brainer to do so again. Really simple and straightforward communication. Antra who dealt with us this time was great and explained everything really well.",
    reviewer: "Adam",
    date: "20th February 2024",
  },
]
