export const MAX_PROPERTY_PRICE = 1500000

export const formattedMaxPropertyPrice = Intl.NumberFormat("en-GB", {
  maximumFractionDigits: 0,
}).format(MAX_PROPERTY_PRICE)

export const formatPrice = (amount: number): string =>
  new Intl.NumberFormat("en-GB", {
    currency: "GBP",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  }).format(amount)

export const isValidPropertyPrice = (value: number): boolean =>
  value > 0 && value < MAX_PROPERTY_PRICE
