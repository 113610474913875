import React from "react"

import { Input, InputQuestion } from "../Input"
import { SharedInputProps, SharedQuestionProps } from "./Shared"
import {
  sanitizeGBP,
  sanitizeFloat,
  sanitizeInteger,
} from "../Internals/sanitizers"

export type Precision = "fractional" | "integral"

export interface GBPInputProps extends SharedInputProps {
  precision?: Precision
}

const preciseInputMode = {
  fractional: "decimal",
  integral: "numeric",
} as const

const preciseSanitizer = {
  fractional: sanitizeFloat,
  integral: sanitizeInteger,
}

export const GBPInput: React.FC<GBPInputProps> = props => {
  const {
    onInput,
    inputDataTestId,
    value = "",
    precision = "fractional",
    ...otherProps
  } = props
  const formattedValue = sanitizeGBP(value.toString())

  return (
    <Input
      dataTestId={inputDataTestId}
      type="text"
      inputMode={preciseInputMode[precision]}
      hint={{
        kind: "left",
        text: "£",
      }}
      onInput={val => {
        const amount = preciseSanitizer[precision](val)
        if (onInput) {
          onInput(amount)
        }
      }}
      value={formattedValue}
      {...otherProps}
    />
  )
}

interface QuestionProps extends SharedQuestionProps {
  precision?: Precision
}

export const GBPInputQuestion: React.FC<QuestionProps> = props => (
  <InputQuestion
    {...props}
    inputComponent={<GBPInput isError={Boolean(props.error)} {...props} />}
  />
)
