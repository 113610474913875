import { SimplePlusQuote } from "./types"

const calculatorApiToken = "Basic SGFiaXRvOiE4T3VWOCRBI05MNA=="

export const getSimplePlusQuote = async (
  propertyPrice: number
): Promise<SimplePlusQuote> => {
  const res = await fetch(`/api/calculator/v2/simple-plus-quote`, {
    method: "POST",
    headers: {
      Authorization: calculatorApiToken,
      "Content-type": "application/json",
    },
    body: JSON.stringify({ propertyValue: propertyPrice }),
  })

  if (res.status !== 200) {
    const body = await res.text()
    throw new Error(
      `There was an error calling /api/calculator/v2/simple-plus-quote: ${res.status} - ${body}`
    )
  }

  const simplePlusQuote = res.json()
  return simplePlusQuote
}
