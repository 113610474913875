const isDigit = (c: string): boolean => c >= "0" && c <= "9"

const sanitizeNumber = (val: string): string =>
  val.split("").filter(isDigit).join("")

export const sanitizeInteger = (val: string): string => {
  if (val === "") {
    return val
  } else {
    // If we receive a float string, drop the decimals
    const integer = val.split(".")[0]
    const number = parseInt(sanitizeNumber(integer))
    if (typeof number === "number" && !isNaN(number)) {
      return number.toString()
    } else {
      return ""
    }
  }
}

export const sanitizeFloat = (val: string): string => {
  if (val.startsWith(".")) {
    // Filter out strings starting with '.'
    return ""
  } else if (val.endsWith(".")) {
    if (val.indexOf(".") === val.lastIndexOf(".")) {
      // Allow strings ending in '.'
      return val
    } else {
      // Filter out extra '.'
      return val.slice(0, -1)
    }
  } else if (val.includes(".")) {
    // This provides a better experience than parseFloat(val).toString()
    const [integer, fractional] = val.split(".")
    return `${sanitizeNumber(integer)}.${sanitizeNumber(fractional)}`
  } else {
    // Since we know it's not a float we can safely sanitize it as an integer
    return sanitizeNumber(val)
  }
}

export const sanitizeGBP = (val: string): string => {
  if (val.startsWith(".")) {
    // Filter out strings starting with '.'
    return ""
  } else if (val.endsWith(".")) {
    if (val.indexOf(".") === val.lastIndexOf(".")) {
      // Allow strings ending in '.'
      return val
    } else {
      // Filter out extra '.'
      return val.slice(0, -1)
    }
  } else if (val.includes(".")) {
    // This provides a better experience than parseFloat(val).toString()
    const [integer, fractional] = val.split(".")
    const sanitizedValue = parseFloat(
      `${sanitizeNumber(integer)}.${sanitizeNumber(fractional)}`
    )

    if (sanitizedValue) {
      return formatGBP(sanitizedValue)
    }
  }

  // Since we know it's not a float we can safely sanitize it as an integer
  const sanitizedValue = parseInt(sanitizeNumber(val))
  if (typeof sanitizedValue === "number" && !isNaN(sanitizedValue)) {
    return formatGBP(sanitizedValue)
  } else {
    return ""
  }
}

const formatGBP = (amount: number): string =>
  amount.toLocaleString("en-GB", {
    maximumFractionDigits: 2,
  })
