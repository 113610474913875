// Copied over from orientation + tweaked a bit

import { css, keyframes } from "@emotion/react"
import { breakpoints, media } from "design-kit"
import React from "react"

const ANIMATION_DURATION = "33s"

/**
 * Safari doesn't seem to play nicely with `transparent` in our gradients, so we
 * use this instead
 *
 * (https://stackoverflow.com/a/27118826)
 */
const TRANSPARENT = "rgba(255, 255, 255, 0)"

const sharedCSS = css`
  position: absolute;
  width: 200%;
  height: 200%;
`

const topCircleAnimationKeyframes = keyframes`
   0%, 100% {
     top: -100%;
     left: -100%;
   }
   25% {
     top: -100%;
     left: 0;
   }
   50% {
     top: 0;
     left: 0;
   }
   75% {
     top: 0;
     left: -100%;
   }
 `

const bottomCircleAnimationKeyframes = keyframes`
   0%, 100% {
     top: 0;
     left: 0;
   }
   25% {
     top: 0;
     left: -100%;
   }
   50% {
    top: -100%;
     left: -100%;
   }
   75% {
     top: -100%;
     left: 0;
   }
 `

export const GradientsBackground: React.FC = () => (
  <div
    css={css`
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;

      ${breakpoints.tablet`
         bottom: unset;
         height: 100%;
         left: unset;
         right: unset;
         top: unset;
         width: 100%;
       `}
    `}
  >
    {/*  Yellow sun, top circle  */}
    <div
      css={css`
        ${sharedCSS}

        background: radial-gradient(
          circle at center,
          #ffdfaa 15%,
          ${TRANSPARENT} 60%
        );
        top: -100%;
        left: -100%;
        ${media.allowsMotion`
          animation: ${topCircleAnimationKeyframes} ${ANIMATION_DURATION} linear
            infinite;
        `}
      `}
    />

    {/* Blue sky, bottom circle */}
    <div
      css={css`
        ${sharedCSS}

        background: radial-gradient(
          circle at center,
          #aed1fc 15%,
          ${TRANSPARENT} 60%
        );

        ${media.allowsMotion`
          animation: ${bottomCircleAnimationKeyframes} ${ANIMATION_DURATION}
            linear infinite;
        `}
      `}
    />
  </div>
)
