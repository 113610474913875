const habitoEase = `cubic-bezier(.25,.01,.25,1)`
const transitionDuration = `1s`

const transitionDelays = {
  sectionHeading: "0s",
  pietro: "0s",
  dashboardCard: "0.1s",
  card1: "0.2s",
  card2: "0.3s",
  card3: "0.4s",
  card4AndKeyMobile: "0.4s",
  card4AndKey: "0.5s",
  sectionSubtitle: "0.6s",
}

const mkTransition = (delay?: string): string => {
  return `opacity ${transitionDuration} ${delay && delay + " "} ${habitoEase},
  transform ${transitionDuration} ${delay && delay + " "} ${habitoEase}`
}

export const transitions = {
  sectionHeading: mkTransition(transitionDelays.sectionHeading),
  pietro: mkTransition(transitionDelays.pietro),
  dashboardCard: mkTransition(transitionDelays.dashboardCard),
  card1: mkTransition(transitionDelays.card1),
  card2: mkTransition(transitionDelays.card2),
  card3: mkTransition(transitionDelays.card3),
  card4AndKeyMobile: mkTransition(transitionDelays.card4AndKeyMobile),
  card4AndKey: mkTransition(transitionDelays.card4AndKey),
  sectionSubtitle: mkTransition(transitionDelays.sectionSubtitle),
}
