import React, { useState } from "react"
import styled from "@emotion/styled"
import {
  breakpoints,
  Card,
  ChevronRightIcon,
  CircleArrow,
  gridSettings,
  horizontal,
  mkColumn,
  mkSubGridRow,
  TertiaryInvertedLink,
  typographyStyles,
  vertical,
} from "design-kit"

import { reviews } from "./reviews"

const subGridSettings = {
  desktop: {
    ...gridSettings.desktop,
    maxColumns: 8,
  },
  tablet: gridSettings.tablet,
  mobile: gridSettings.mobile,
}

const SubGridRow = mkSubGridRow(breakpoints)(subGridSettings)
const subColumn = mkColumn(breakpoints)(subGridSettings)

const ReviewCard = styled(Card)`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 6,
  })}
`

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${typographyStyles.headline};
  min-height: 12em;

  ${breakpoints.tablet`
    min-height: 8em;
  `}

  ${breakpoints.desktop`
    ${typographyStyles.statement4};
    min-height: 9em;
  `}
`

const Name = styled.span`
  display: inline-block;
  margin-right: ${horizontal.s};
  ${typographyStyles.bodyBold};
`

const Date = styled.span`
  display: inline-block;
  ${typographyStyles.metaText};
`

const ArrowButtonContainer_ = styled.div`
  ${subColumn({
    widthMobile: 2,
    widthTablet: 3,
    widthDesktop: 1,
  })}
  display: flex;
  align-items: center;
  margin-top: ${vertical.xs};
`

const LeftArrowButtonContainer = styled(ArrowButtonContainer_)`
  justify-content: flex-start;

  ${breakpoints.desktop`
    order: -1;
  `}
`

const RightArrowButtonContainer = styled(ArrowButtonContainer_)`
  justify-content: flex-end;
`

const ReadMoreLinkContainer = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 7,
    offsetDesktop: 1,
  })}
  margin-top: ${vertical.xs};
`

const ReadMoreLink = styled(TertiaryInvertedLink)`
  text-align: left;
`

export const ReviewCards: React.FC = () => {
  const [currentIdx, setCurrentIdx] = useState<number>(0)

  const currentReview = reviews[currentIdx]

  return (
    <SubGridRow>
      <ReviewCard element="div">
        <Figure>
          <blockquote>{currentReview.quote}</blockquote>
          <figcaption>
            <Name>{currentReview.reviewer}</Name>
            <Date>{currentReview.date}</Date>
          </figcaption>
        </Figure>
      </ReviewCard>

      <LeftArrowButtonContainer>
        <CircleArrow
          arrowColour="black"
          filled={true}
          size="large"
          direction="left"
          aria-label="Previous review"
          onClick={() => {
            if (currentIdx > 0) {
              setCurrentIdx(currentIdx - 1)
            }
          }}
          disabled={currentIdx === 0}
        />
      </LeftArrowButtonContainer>

      <RightArrowButtonContainer>
        <CircleArrow
          arrowColour="black"
          filled={true}
          size="large"
          direction="right"
          aria-label="Next review"
          onClick={() => {
            if (currentIdx < reviews.length - 1) {
              setCurrentIdx(currentIdx + 1)
            }
          }}
          disabled={currentIdx === reviews.length - 1}
        />
      </RightArrowButtonContainer>

      <ReadMoreLinkContainer>
        <ReadMoreLink
          href="https://uk.trustpilot.com/review/habito.com"
          target="_blank"
          text="Read more customer stories"
          icon={{
            kind: "right",
            icon: <ChevronRightIcon />,
          }}
          width="content-width"
        />
      </ReadMoreLinkContainer>
    </SubGridRow>
  )
}
