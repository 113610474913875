import {
  logClickedButton,
  logSubmittedForm,
} from "@heyhabito/amplitude/src/lib"
import { amplitude } from "../../utils/amplitude"

const pageName = "home-buying"

export const logButtonClick = (
  buttonName: string,
  interaction_container: string
): void => {
  logClickedButton(amplitude, buttonName, "", interaction_container, pageName)
}

export const logLinkClick = (
  buttonName: string,
  href: string,
  interaction_container: string
): void => {
  logClickedButton(amplitude, buttonName, href, interaction_container, pageName)
}

export const logFormSubmit = (
  formName: string,
  formData: Record<string, unknown>
): void => {
  logSubmittedForm(amplitude, formName, formData, pageName)
}
