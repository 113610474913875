import React, { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  column,
  GridRow,
  HandPointDownIcon,
  HeadlineLarge,
  horizontal,
  media,
  PrimaryLink,
  SecondaryTwoCTA,
  typographyStyles,
  useMediaQuery,
  vertical,
} from "design-kit"

import cloudPng from "../assets/cloud.png"
import cloudWebp from "../assets/cloud.webp"
import { logButtonClick, logLinkClick } from "../amplitude"

const Title = styled.h1`
  ${typographyStyles.statement2}
`

const cloud = (
  <picture>
    <source srcSet={cloudWebp} type="image/webp" />
    <img
      src={cloudPng}
      alt=""
      css={css`
        display: none;
        user-select: none;

        ${breakpoints.tablet`
          display: block;
          position: absolute;
          top: 128px;
          right: -216px;
        `}

        ${breakpoints.desktop`
          top: 160px;
          right: -100px;
        `}
      `}
    />
  </picture>
)

export const HeroUpper: FC<{ scrollRef: React.RefObject<HTMLDivElement> }> = ({
  scrollRef,
}) => {
  const allowAnimation = useMediaQuery(media.allowsMotion.query)

  return (
    <GridRow>
      <section
        css={css`
          ${column({
            widthMobile: 4,
            widthTablet: 6,
            widthDesktop: 7,
            offsetDesktop: 1,
          })}
          padding-top: ${vertical.xl};

          ${breakpoints.desktop`
            padding-top: ${vertical.xxl};
          `}
        `}
      >
        {cloud}
        <Title>Buying a home just got a whole lot easier</Title>
        <HeadlineLarge
          css={css`
            margin-top: ${vertical.s};
          `}
        >
          Everything you need in one, peaceful place
        </HeadlineLarge>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin-top: ${vertical.m};
          `}
        >
          <PrimaryLink
            text="Take me straight there"
            href="/how-can-we-help/new-mortgage"
            descriptiveText="Take me straight to sign-up"
            onClick={() => {
              logLinkClick(
                "take_me_straight_there",
                "/how-can-we-help/new-mortgage",
                "hero"
              )
            }}
            width={{
              mobile: "full-width",
              tablet: "content-width",
              desktop: "content-width",
            }}
          />
          <SecondaryTwoCTA
            text="Tell me more"
            onClick={() => {
              logButtonClick("tell_me_more", "hero")
              const considerateScrollBehavior = allowAnimation
                ? "smooth"
                : "auto"
              if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                  behavior: considerateScrollBehavior,
                })
              }
            }}
            icon={{ kind: "right", icon: <HandPointDownIcon /> }}
            width={{
              mobile: "full-width",
              tablet: "content-width",
              desktop: "content-width",
            }}
            css={css`
              margin-top: ${vertical.xs};

              ${breakpoints.tablet`
                margin-top: 0;
                margin-left: ${horizontal.l};
              `}
            `}
          />
        </div>
      </section>
    </GridRow>
  )
}
